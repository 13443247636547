<template>
  <component :is="wrapper" class="o-boxes__box rounded-xl shadow-xl bg-white">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Box",
  props: {
    wrapper: {
      default: "div",
      required: false,
      type: String,
    },
  },
};
</script>
